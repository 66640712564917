<template>
  <div v-if="!isLoading">
    <PaymentTutorial title="Pembayaran" v-if="!isFullCoin" :loyaltyUsage="loyaltyUsage" :subTotal="subTotal" :dataFetch="data"/>
    <PaymentFullCoin v-else :loyaltyUsage="loyaltyUsage" :subTotal="subTotal" :dataFetch="data"/>
    <div v-if="!isFullCoin" class="card border-0 shadow-none col-12 d-flex align-items-end">
      <button @click="$router.push({ path: '/purchase/' })" class="btn btn-bottom w-auto">
        {{ $t('menu.purchase.how_to_pay.status_delivery') }}
      </button>
    </div>
  </div>
</template>

<script>
import PaymentTutorial from '@/components/Purchase/PaymentTutorial.vue'
import PaymentFullCoin from '@/components/Purchase/PaymentFullCoin.vue'

export default {
  name: 'how-to-pay',
  components: {
    PaymentTutorial,
    PaymentFullCoin,
  },
  data: function () {
    return {
      isLoading: false,
      isFullCoin: false,
      loyaltyUsage: 0,
      subTotal: 0,
      data: [],
    }
  },
  methods: {
    getOrderDetail() {
      this.isLoading = true
      this.$store.dispatch('purchase/GETDETAIL', {
        order_id: this.$route.params.order_id,
        channel_id: this.$store.state.app.channel_id,
      })
        .then(({ data }) => {
          this.data = data
          this.isFullCoin = Boolean(data.gross_total === 0)
          this.loyaltyUsage = data.loyalty_usage ?? 0
          this.subTotal = data.sub_total ?? 0
          this.isLoading = false
        })
        .catch(err => {
          this.isLoading = false
          this.$notification.error({
            message: 'Gagal memuat data',
            description: err.response?.data?.message || err.message,
          })
        })
    },
  },
  mounted() {
    this.getOrderDetail()
  },
}
</script>

<style lang="scss">
  .btn-bottom {
    background: #000000;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    border: none;
    width: 275px;
    padding: 10px;
  }
</style>
